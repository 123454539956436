.form-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}
.form {
  position: relative;
  top: 65px;
  width: 480px;
  height: auto;
  background: #ececec;
  margin: auto;
}
.form__separation {
  width: 100%;
  height: 60px;
  font-size: 16px;
  letter-spacing: 0.9px;
}
.form__submit--seller {
  text-align: center;
}
.form .input {
  width: 270px;
  padding: 0 4px 0 8px;
  height: 33px;
  font-size: 14px;
  border: 1px solid #b6b5b5;
  outline: none;
  /* box-shadow: 1px 1px 1px rgba(255,221,181,1); */
  background-color: #fff;
  border-radius: 3px;
  margin: 0px;
}
.form .input[type="password"]:focus {
  border: 1px solid #ff9000;
}
.form .input:focus {
  border: 1px solid #ff9000;
}
.form .input[name="phone"] :-moz-placeholder {
  letter-spacing: 1.5px;
}
.form .input[name="phone"] ::-webkit-input-placeholder {
  letter-spacing: 1.5px;
}
.error-input {
  border: 1px solid #dd2222 !important;
  outline: none !important;
  border-radius: 3px !important;
  margin: 0px !important;
}
.form-group {
  letter-spacing: 0.7px;
  display: flex;
  margin-bottom: 23px;
}
.form-group__error {
  letter-spacing: -0.2px;
  display: none;
  color: #dd2222;
  direction: rtl;
  padding-right: 5px;
  height: 0px;
}
.form-group__error--wrong_password {
  position: absolute !important;
  bottom: 152%;
  left: 6%;
  font-size: 13px;
  letter-spacing: -0.2px;
  display: none;
  color: #dd2222;
  padding-right: 5px;
  height: 0px;
}
.form-group__attention {
  top: 88%;
  left: 4%;
  position: absolute !important;
  color: #888888;
  direction: rtl;
  padding-right: 5px;
  height: 0px;
  font-size: 13px !important;
  letter-spacing: -0.3px;
}
.form-group__attention--color {
  position: absolute;
  bottom: 110%;
  color: #000;
  font-size: 13px !important;
}
.form-group__label {
  display: flex;
  align-items: center;
  padding: 0 10px 0 20px;
  direction: rtl;
  width: 123px;
}
.form-group__input {
  position: relative;
  width: auto;
}
.form-group__input--password {
  position: relative;
}
.form-group__input--password :-moz-placeholder {
  letter-spacing: 4.5px;
  font-weight: 900;
  font-size: 16px;
}
.form-group__input--password ::-webkit-input-placeholder {
  etter-spacing: 4.5px;
  font-weight: 900;
  font-size: 16px;
}
.form-group__submit {
  height: 40px !important;
  width: 160px !important;
  margin-bottom: 25px;
  padding: 0 !important;
  letter-spacing: 0.7px !important;
  font-size: 16px !important;
  margin-bottom: 12px !important;
}
.form-group__disabled {
  border: 1px solid #ececec !important;
  background: #ececec !important;
  color: #000 !important;
}
.form-group__disabled:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #ececec !important;
}
.form-group--invisible {
  margin-top: -8px;
  padding-top: 6px;
  padding-bottom: 22px;
  padding-right: 6px;
  letter-spacing: 0.7px;
  display: flex;
  margin-bottom: -6px;
}
.form-group--invisible .form-group__label {
  font-weight: bold !important;
  letter-spacing: 0.7px !important;
  padding-left: 20px !important;
}
.form-group--error .form-group__error {
  display: block;
  font-size: 13px;
}
.enter {
  padding: 20px 0px 5px 0px;
}
.enter__name {
  height: 50px;
  width: calc(240px);
}
.tab {
  height: 55px;
  float: left;
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
  cursor: pointer;
}
.passive {
  background: #fff;
}
/*в другой*/
.registration-client {
  display: none ;
  padding: 20px 0 0 0;
}
.registration-client__buyer .form-group__label {
  padding: 0 3px 0 32px;
}
.registration-client__buyer .form-group__input {
  padding-left: 7px;
}
.registration-client__buyer .link-box {
  padding-top: 10px;
}
.registration-client__buyer .link-box input {
  width: 245px !important;
}
.registration-client__buyer .link-box__link {
  margin-bottom: 13px;
}
.registration-client__seller {
  height: auto;
}
.registration-client__seller .form-group {
  padding: 0 0 0 25px;
}
.registration-client__seller .link-box {
  padding-top: 21px;
}
.registration-client__seller .link-box .form-group__submit {
  width: 240px !important;
}
.registration-client__seller .link-box__link {
  padding-bottom: 21px;
}
.registration-challenge {
  font-size: 16px;
  letter-spacing: 0.7px;
  padding-left: 65px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  height: 43px;
}
.link-box {
  padding: 7px 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.link-box__link {
  padding-bottom: 5px;
  font-size: 13px;
  letter-spacing: 0.1px;
}
.radiobutton {
  width: 140px;
}
.radiobutton input {
  display: none;
}
.radiobutton span {
  margin-right: 5px;
}
.radiobutton input + label span {
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  background: #fff;
  cursor: pointer;
  border: 1px solid #aaa;
  border-radius: 8px;
}
.radiobutton input:checked + label span {
  position: relative;
}
.radiobutton input:checked + label span:before {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  background: #1b9b0b;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
}
/*Окно успеха регистрации покупателя*/
.form-registration__buyer {
  display: block;
  padding: 0 28px 0 28px;
}
.form-registration__buyer .form-group__error {
  position: absolute;
  top: 100%;
  right: 1%;
  padding-top: 2px;
  padding-right: 4px;
  letter-spacing: -0.35px;
}
.form-registration__buyer .form-group__input {
  padding-left: 11px !important;
}
.form-registration__buyer .form-group__input--password {
  padding-left: 11px !important;
}
.form-registration__buyer .form-group__label {
  padding-right: 0px;
  padding-left: 3px;
}
.form-registration__buyer .form-group__submit {
  width: 240px !important;
}
.form-registration__buyer .form-group__attention {
  font-size: 13px !important;
  direction: ltr;
  padding-top: 4px;
  padding-left: 7px;
}
.form-registration__buyer .link-box {
  padding-top: 20px;
  padding-bottom: 0px;
}
.form-registration__buyer .link-box__link {
  padding-bottom: 19px;
  letter-spacing: -0.4px;
}
.success_buyer_registration {
  display: none;
}
.registration-success {
  /*display: block !important;*/
  height: auto;
  font-size: 15px;
  color: #000;
}
.registration-success__title {
  text-align: center;
  padding: 18px 0px 9px 0;
}
.registration-success__subtitle {
  text-align: center;
  padding: 19px;
}
.registration-success__logo {
  text-align: center;
  padding-bottom: 15px;
}
.registration-success__logo img {
  height: 50px;
}
.registration-success__text {
  padding-left: 34px;
  display: flex;
  align-items: center;
}
.registration-success__text img {
  height: 23px;
}
.registration-success__text--seller {
  text-align: center;
  letter-spacing: 0.7px;
  padding-top: 15px;
  padding-bottom: 150px;
}
.input__change-pen {
  display: none;
  position: absolute;
  right: 0;
  top: 28%;
  cursor: pointer;
}
.attention__link {
  position: absolute;
  bottom: 110%;
  right: 0%;
  text-decoration: none;
  color: #888888;
  font-size: 12px;
}
.seller {
  display: none;
  letter-spacing: 0.7px;
  padding: 132px 10px 0 10px;
}
.seller .form-group__submit--seller {
  font-size: 18px;
  padding-left: 63px;
  padding-right: 64px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 11px;
}
.password-message {
  padding-top: 20px;
  padding-left: 32px;
  padding-right: 32px;
  font-size: 15px;
  display: none;
}
.password-message__title {
  padding-bottom: 37px;
}
.password-message__subtitle {
  padding-top: 7px;
}
.password-message .link-box {
  padding-top: 73px;
}
.password-message .link-box__link {
  text-align: center;
  padding-bottom: 14px;
}
.password-message .link-box .form-group__submit--seller {
  padding-top: 10px;
  padding-left: 29px;
  padding-right: 29px;
  font-size: 18px;
  margin-bottom: 13px;
}
.change-pen {
  width: 10px;
}
.buyer {
  display: none;
  padding-top: 19px;
}
.buyer .registration-success__logo {
  padding-bottom: 17px !important;
}
.buyer .link-box {
  padding-top: 42px;
}
.buyer .form-group__submit {
  width: 240px !important;
}
.buyer .registration-success__text {
  padding-left: 33px;
  display: flex;
  align-items: stretch;
}
.buyer .registration-success__text .text__img {
  padding-right: 4px;
}
.buyer .registration-success__text--last {
  padding-left: 33px;
  display: flex;
  align-items: stretch;
  margin-top: -2px;
}

/*# sourceMappingURL=sourcemaps/form.css.map */
