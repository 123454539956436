.box {
  color: #fe33ac;
  border-color: #fdcdea;
}
.box div {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.arr {
  color: red;
}
.open-dialog-has-sended {
  width: 20px;
  height: 20px;
  background-image: url(/img/msg-sended.png);
  background-position: center center;
  background-size: contain;
  top: 2px;
  right: 44px;
  position: absolute;
  display: block;
  opacity: 0.2;
}
.open-dialog-has-sended .open-dialog-has-sended__info {
  display: none;
}
.open-dialog-has-sended:hover {
  cursor: help;
  opacity: 1;
}
.open-dialog-has-sended:hover .open-dialog-has-sended__info {
  background: #ffffff;
  -webkit-box-shadow: 4px 9px 19px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 9px 19px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 9px 19px -8px rgba(0, 0, 0, 0.75);
  position: absolute;
  top: -1px;
  left: -205px;
  width: 195px;
  height: 14px;
  display: block;
  font-size: 12px;
  padding: 4px;
}
.attach_maessage {
  padding: 10px;
  margin-top: 5px;
  background-color: #eaeaea;
  padding-left: 30px;
  border: 1px solid #cacaca;
}
.attach_maessage .attach_message__link {
  position: relative;
}
.attach_maessage .attach_message__link:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url(/img/link_seo.png);
  background-position: center center;
  background-size: contain;
  left: -25px;
  top: -2px;
}
#header-menu.header-menu-order-layer {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
body.bot_mode textarea {
  /*
    height: auto !important;
    */
}
body.bot_mode .questions-block__place-block .container-fluid {
  padding: 0px;
}
body.bot_mode .questions-block__place-block .container-fluid .question-block__element-top {
  padding-left: 10px;
}
/*
select {
  -moz-appearance: button-arrow-down;
  -webkit-appearance:none;
}
select::-ms-expand {
  display: none;
}

select {
  -moz-appearance: none;
}
*/
.order-info__discuss-header {
  font-weight: bold;
  font-size: 16px;
}
.order-info__discuss-pad {
  padding: 10px;
}
.order-info__comment {
  margin-top: 15px;
  margin-left: 15px;
}
.text-black {
  color: black;
}
.order-info__comment {
  font-size: 14px;
}
.order-info__discuss-body blockquote {
  border-left: 5px solid #dca7a7;
  background: white;
}
.container-fluid.order-info__discuss-open {
  padding-left: 0px;
  margin-left: 0px;
}
tr {
  position: relative;
}
.safedeal-price-icon {
  height: 59px;
  left: -1px;
  position: relative;
  top: -10px;
}
.garant_seller_img_safecrow {
  margin: 4px 0 0 23px;
  float: left;
  width: 100px;
}
.mini_header_tab.safedeal-head {
  margin-bottom: 8px;
}
.big_text_tabs.safedeal-tab-big-text {
  font-weight: normal;
  padding-bottom: 7px;
  display: block;
}
.garant_seller_img__safedeal {
  width: 112px;
  margin-top: 11px;
  margin-left: 17px;
}
.num-list-how-to-buy {
  margin-left: 16px;
  margin-top: -5px;
  line-height: 1.2;
}
a {
  cursor: pointer;
}

/*# sourceMappingURL=sourcemaps/stylesless.css.map */
